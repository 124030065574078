<template>
  <d-container fluid>
    <pageTitle title="Voertuig opties" :back="true"/>
    <d-row>
      <vCard header="test" col="12" fluid>
        <vTable :fields="fields" :actions="actions" :data="data"/>
      </vCard>
    </d-row>
    <d-modal v-if="modalSetVehicleOptionGroup" @close="modalSetVehicleOptionGroupCloseHandler" size="lg">
      <d-modal-header>
        <d-modal-title>Leverancier</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <vehicleOptionTableRow :vehicleOption="vehicleOption" @delete="removeFromVehicleOptionsWithoutGroup"/>
      </d-modal-body>
    </d-modal>
  </d-container>
</template>

<script>
import vehicleOptionTableRow from '../components/VehicleOptionTableRow'

export default {
  name: '',
  components: {
    vehicleOptionTableRow
  },
  data() {
    return {
      modalSetVehicleOptionGroup: false,
      vehicleOption: null,
      actions: [
        {
          icon: '<i class="material-icons">visibility</i>',
          fn: (vehicleOptionCategory) => {
            // this.$router.push({
            //   name: 'dashboard/inkoop/vehicleOptions/vehicleOptionsByCategory',
            //   params: {category_id: vehicleOptionCategory.id}
            // })
          }
        },
        {
          icon: '<i class="material-icons">build</i>',
          fn: (vehicleOptionCategory) => {
            this.vehicleOption = vehicleOptionCategory
            this.modalSetVehicleOptionGroup = true
          }
        }
      ],
      data: null,
      fields: [
        {
          name: 'name',
          title: 'Optie category',
          sortField: 'name'
        },
        'actions'
      ],
    }
  },
  created() {
    this.getVehicleOptionGroupsByCategory(this.$route.params.category_id)
  },
  methods: {
    async getVehicleOptionGroupsByCategory(categoryId) {
      try {
        let {data} = await this.$api.vehicleOptionGroup.getByCategory({id: categoryId})
        this.data = data
      } catch (e) {
      }
    },
    modalSetVehicleOptionGroupCloseHandler() {
      this.modalSetVehicleOptionGroup = false
    }
  }
}
</script>

<!--<style module>-->
<!--tr {-->
<!--  display: flex;-->
<!--  flex: 1;-->
<!--}-->
<!--tr > td {-->
<!--  flex: 1;-->
<!--}-->
<!--</style>-->